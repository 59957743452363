import { PostTypeEnum } from "~/types/asgard/PostType";

type HrefCallback = (value: string) => string;

export type PostToRoutePostTypes =
  | PostTypeEnum.Analysis
  | PostTypeEnum.AnalystReport
  | PostTypeEnum.Article
  | PostTypeEnum.Author
  | PostTypeEnum.TrendSetter
  | PostTypeEnum.InstantIntel
  | PostTypeEnum.QuestionAnswer
  | PostTypeEnum.Tracker
  | PostTypeEnum.Vertical
  | PostTypeEnum.LandingPage
  | PostTypeEnum.SubscriptionCheckout
  | PostTypeEnum.ReportLibrary
  | PostTypeEnum.Scooter;

const postTypeToRoute: Record<PostToRoutePostTypes, HrefCallback> = {
  analysis: (param) => `/analysis/${param}`,
  analyst_report: (param) => `/analyst-reports/${param}`,
  article: (param) => `/articles/${param}`,
  author: (param) => `/authors/${param}`,
  trend_setter: (param) => `/trend-setters/${param}`,
  instant_intel: (param) => `/instant-intel/${param}`,
  q_and_a: (param) => `/questions-and-answers/${param}`,
  tracker: (param) => `/trackers/${param}`,
  vertical: (param) => `/verticals/${param}`,
  landing_page: (param) => `/${param}`,
  subscription_checkout: (param) =>
    `/subscription-checkout?subscription=${param}`,
  reports: (param) => `/reports/${param}`,
  scooter: (param) => `/scooters/${param}`,
};

export const getRouteFromPostType = (type: PostTypeEnum, param: string) =>
  postTypeToRoute[type as PostToRoutePostTypes]?.(param) ?? null;
