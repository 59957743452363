import { commonConfig } from "~/config/common-config";

const whitelistedUrls = ["https://morningconsult.zoom.us"];

const rootDomain = (url: URL): string => {
  const parts = url.hostname.split(".");
  return parts.slice(-2).join(".");
};

/**
 * Checks if the origin ends with the root domain of the url
 *
 * For instance,
 *
 * Origin: https://pro.morningconsult.com/foobar
 * Url:    https://ai.morningconsult.com/bingo
 *
 * both end with the root domain "morningconsult.com"
 */
const isSharedRootDomain = (url: URL, origin: string): boolean =>
  origin.endsWith(rootDomain(url));

export const isUrlSafeForNavigate = (
  urlToTest: string,
  origin: string = commonConfig.NEXT_PUBLIC_ENDIL_URL
): boolean => {
  // for relative urls, we can assume they are safe to navigate to.
  if (urlToTest.startsWith("/")) {
    return true;
  }
  try {
    const url = new URL(urlToTest);
    return (
      url.origin === origin ||
      isSharedRootDomain(url, origin) ||
      whitelistedUrls.includes(url.origin)
    );
  } catch (err) {
    return false;
  }
};
