import { UserTraits } from "@segment/analytics-next";
import { createContext } from "react";
import { UserIdentity } from "~/lib/shared/formatUserIdentity";

export type AnalyticsContentProperties = {
  headline: string;
  authors: string[];
  primaryCategory: string;
  categories: string[];
  tags: string[];
  contentType: string;
};

export type AnalyticsIdentity = UserTraits & UserIdentity;

export type AnalyticsData = {
  anonymousUserIdentified: boolean;
  analyticsUserIdentified: boolean;
  identity: AnalyticsIdentity | null;
  lastAnalyticsPagePath: string | null;
  refererPath: string | null;
  enterPageTs: number;
};

export type AnalyticsContextData = {
  shallowMergeAnalyticsData: (newAnalyticsData: Partial<AnalyticsData>) => void;
  getAnalyticsContentProperties: () =>
    | { content: AnalyticsContentProperties }
    | {};
  setAnalyticsContentProperties: (
    newAnalyticsContentProperties: AnalyticsContentProperties | null
  ) => void;
  setAnalyticsUserId: (id: string | null) => void;
  /**
   * The id used by segment to track a user. This is different from identity.id,
   * which is only populated for authenticated users
   */
  analyticsUserId: string | null;
  setAnalyticsAnonymousId: (id: string | null) => void;
  /**
   * The id used by segment to track a user. This will only ever be populated and used for unauthenticated users
   */
  analyticsAnonymousId: string | null;
} & AnalyticsData;

export const AnalyticsContext = createContext<AnalyticsContextData>(
  // Initial state should never be used so we just set it to an empty object
  {} as AnalyticsContextData
);
