import { Session } from "next-auth";
import { MCPermissions } from "~/common/constants";

export type UserIdentity =
  | {
      isMcInternalUser: false;
      isLoggedIn: false;
      isEnterpriseSubscriber: false;
      isProSubscriber: false;
    }
  | {
      id: string;
      company?: {
        name: string;
        id: string;
      };

      title?: string;
      createdAt?: string;

      isClientAdmin: boolean;
      isCmsUser: boolean;
      isDeveloper: boolean;
      isMcUser: boolean;
      isTest: boolean;
      isEnterpriseSubscriber: boolean;
      isMcInternalUser: boolean;
      isProSubscriber: boolean;
      isLoggedIn: true;

      hubspotId?: string;

      // Compat with MCI reporting
      "User UID": string;
      "Tenant UID"?: string;
      "Tenant Name"?: string;
      Products: string[];
    };

export const formatUserIdentityInformation = (
  session: Session | null
): UserIdentity => {
  if (session === null) {
    return {
      isMcInternalUser: false,
      isLoggedIn: false,
      isEnterpriseSubscriber: false,
      isProSubscriber: false,
    };
  }

  const products: string[] = [];

  if (session.team) {
    session.team.permissions.forEach((permission) => {
      switch (permission) {
        case MCPermissions.DELIVERABLES_HUB_PERM:
          products.push("DH");
          break;
        case MCPermissions.BRANDS_PERM:
          products.push("MCBI");
          break;
        case MCPermissions.ECONOMIC_PERM:
          products.push("MCEI");
          break;
        case MCPermissions.POLITICAL_PERM:
          products.push("MCPI");
          break;
        case MCPermissions.AUDIENCE_PROFILE_PERM:
          products.push("MCAP");
          break;
        default:
      }
    });
  }
  return {
    id: session.mcAccountId,
    company: session.team
      ? {
          name: session.team.name,
          id: session.team.id,
        }
      : undefined,
    title: session.user.title,
    createdAt: session.user.createdAt,

    isClientAdmin: !!session.user.isClientAdmin,
    isDeveloper: !!session.user.isDeveloper,
    isMcUser: !!session.user.isMcUser,
    isCmsUser: !!session.user.isCmsUser,
    isTest: !!session.user.isTest,

    isEnterpriseSubscriber: Boolean(session.user.isTeamSubscriber),
    isMcInternalUser: Boolean(session.user.isMcUser),
    isProSubscriber: Boolean(session.user.isActiveSubscriber),
    isLoggedIn: true,

    hubspotId: session.user.hubspotId,

    // Compat with MCI reporting
    "User UID": session.mcAccountId,
    "Tenant UID": session.team ? session.team.id : undefined,
    "Tenant Name": session.team ? session.team.name : undefined,
    Products: products,
  };
};
