// This is specifically used for the "next-auth/react" signIn() method's callbackUrl option
//
// Note: We do not do further validation of the urlOrPath here because there are 2 places that
// validate the urlOrPath:
//
// 1. The server-side code on registration.page.tsx first performs a check
// 2. The login page performs a check before actually redirecting
//
export const createLoginRedirectCallbackUrl = (urlOrPath: string): string =>
  `/login?redirect=${encodeURIComponent(urlOrPath)}`;
