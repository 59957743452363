import { usePersistentState } from "~/components/PersistentState/PersistentStateProvider";

export const useFirstLoginModal = () => {
  const { showFirstLoginModal, setShowFirstLoginModal } = usePersistentState();
  return {
    showFirstLoginModal,
    setShowFirstLoginModal,
  };
};

export const useOnboardingModal = () => {
  const { showOnboardingModal, setShowOnboardingModal } = usePersistentState();
  return {
    showOnboardingModal,
    setShowOnboardingModal,
  };
};

export const useIsNavigationExpanded = () => {
  const { isNavigationExpanded, setIsNavigationExpanded } =
    usePersistentState();
  return { isNavigationExpanded, setIsNavigationExpanded };
};

export const useRegistrationReminderBanner = () => {
  const { registrationReminderEmail, setRegistrationReminderEmail } =
    usePersistentState();
  return {
    registrationReminderEmail,
    setRegistrationReminderEmail,
  };
};

export const useProcessLogin = () => {
  const {
    processLoginStart,
    setProcessLoginStart,
    processLoginSuccessID,
    setProcessLoginSuccessID,
  } = usePersistentState();
  return {
    processLoginStart,
    setProcessLoginStart,
    processLoginSuccessID,
    setProcessLoginSuccessID,
  };
};
