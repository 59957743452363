import { ReactNode, useCallback, useRef, useState } from "react";
import {
  AnalyticsContentProperties,
  AnalyticsContext,
  AnalyticsData,
} from "~/components/Analytics/AnalyticsContext";
import { AnalyticsHandleRouteChange } from "~/components/Analytics/AnalyticsHandleRouteChange";
import { AnalyticsIdentifyUser } from "~/components/Analytics/AnalyticsIdentifyUser";
import { formatUserIdentityInformation } from "~/lib/shared/formatUserIdentity";

export const AnalyticsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  /* Using a ref here to persist values between renders and ensure that
  properties used for analytics events are updated immediately via mutation.
  This is to avoid Context updates which might need to be sequenced correctly
  since the initial page render will provide this data. */
  const analyticsContentProperties = useRef<AnalyticsContentProperties | null>(
    null
  );

  const getAnalyticsContentProperties = useCallback(() => {
    if (analyticsContentProperties.current === null) {
      return {};
    }
    return { content: { ...analyticsContentProperties.current } };
  }, [analyticsContentProperties]);

  const setAnalyticsContentProperties = useCallback(
    (newContentProperties: AnalyticsContentProperties | null): void => {
      analyticsContentProperties.current = newContentProperties;
    },
    [analyticsContentProperties]
  );

  const [analyticsData, setAnalyticsData] = useState<AnalyticsData>({
    analyticsUserIdentified: false,
    anonymousUserIdentified: false,
    identity: formatUserIdentityInformation(null),
    lastAnalyticsPagePath: null,
    refererPath: null,
    enterPageTs: Date.now(),
  });

  const shallowMergeAnalyticsData = useCallback(
    (newAnalyticsData: Partial<AnalyticsData>) =>
      setAnalyticsData((previousData) => ({
        ...previousData,
        ...newAnalyticsData,
      })),
    []
  );

  const [analyticsUserId, setAnalyticsUserId] = useState<string | null>(null);
  const [analyticsAnonymousId, setAnalyticsAnonymousId] = useState<
    string | null
  >(null);

  return (
    <AnalyticsContext.Provider
      value={{
        ...analyticsData,
        shallowMergeAnalyticsData,
        getAnalyticsContentProperties,
        setAnalyticsContentProperties,
        setAnalyticsUserId,
        analyticsUserId,
        setAnalyticsAnonymousId,
        analyticsAnonymousId,
      }}
    >
      <AnalyticsIdentifyUser />
      <AnalyticsHandleRouteChange />
      {children}
    </AnalyticsContext.Provider>
  );
};
