import { EventProperties, SegmentEvent } from "@segment/analytics-next";
import { useCallback, useContext } from "react";
import { AnalyticsContext } from "~/components/Analytics/AnalyticsContext";
import {
  getDeviceBreakpoint,
  getScrollDepth,
} from "~/components/Analytics/getAdditionalTrackingProperties";
import { getCustomRefererProperties } from "~/components/Analytics/getCustomRefererProperties";
import { analyticsPage, analyticsTrack } from "~/lib/client/analytics";

export const useAnalytics = () => {
  const {
    identity,
    getAnalyticsContentProperties,
    analyticsUserIdentified,
    anonymousUserIdentified,
    refererPath,
    shallowMergeAnalyticsData,
  } = useContext(AnalyticsContext);

  const analyticsRefreshUserIdentify = useCallback(() => {
    shallowMergeAnalyticsData({
      analyticsUserIdentified: false,
      anonymousUserIdentified: false,
    });
  }, [shallowMergeAnalyticsData]);

  const analyticsEnhancedTrack = useCallback(
    (eventName: string | SegmentEvent, properties?: EventProperties) =>
      analyticsTrack(
        eventName,
        {
          ...getDeviceBreakpoint(),
          ...getScrollDepth(),
          ...getAnalyticsContentProperties(),
          ...getCustomRefererProperties(refererPath),
          ...properties,
        },
        {
          /**
           * Segment does not guarantee ordering of identify and track events.
           *
           * Amplitude expects events to be strictly sequenced between identify
           * and tracked events.
           *
           * The result is that many events in Amplitude have lost the user
           * context. By passing `traits` here which are mapped to user properties
           * via the Segment <> Amplitude Server Actions integration - each tracked
           * event should include the appropriate user context.
           *
           * Under the hood, segment maps `traits` to `user_properties`:
           *
           * https://www.docs.developers.amplitude.com/analytics/apis/http-v2-api/#keys-for-the-event-argument
           *
           * This mapping can be configured in Segment's settings.
           */
          traits: identity ?? undefined,
          integrations: {
            All: true,

            /**
             * Only allow events to Iterable if the user is logged in.
             *
             * Iterable rejects all events without an associated user.
             */
            ...(identity?.isLoggedIn
              ? {}
              : { Iterable: false, "Hubspot Web (Actions)": false }),
          },
        }
      ),
    [getAnalyticsContentProperties, identity, refererPath]
  );

  const analyticsEnhancedPage = useCallback(
    (pageChangeRefererPath: string | null) =>
      analyticsPage(
        {
          ...getDeviceBreakpoint(),
          ...getAnalyticsContentProperties(),
          ...getCustomRefererProperties(pageChangeRefererPath),
        },
        {
          /**
           * See above.
           */
          traits: identity ?? undefined,
          integrations: {
            All: true,
            ...(identity?.isLoggedIn
              ? {}
              : { Iterable: false, "Hubspot Web (Actions)": false }),
          },
        }
      ),
    [getAnalyticsContentProperties, identity]
  );

  return {
    analyticsRefreshUserIdentify,
    analyticsUserIdentified,
    anonymousUserIdentified,
    analyticsEnhancedTrack,
    analyticsEnhancedPage,
  };
};
