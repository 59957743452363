import { useRouter } from "next/router";
import { useContext, useEffect } from "react";
import { AnalyticsContext } from "~/components/Analytics/AnalyticsContext";
import { useAnalytics } from "~/components/Analytics/useAnalytics";

export const AnalyticsHandleRouteChange = (): null => {
  const {
    analyticsUserIdentified,
    anonymousUserIdentified,
    enterPageTs,
    lastAnalyticsPagePath,
    shallowMergeAnalyticsData,
    setAnalyticsContentProperties,
  } = useContext(AnalyticsContext);

  const router = useRouter();
  const { asPath: routerPath, isReady } = router;
  const { analyticsEnhancedTrack, analyticsEnhancedPage } = useAnalytics();

  useEffect(() => {
    const setLastAnalyticsPagePath = (newLastAnalyticsPagePath: string) =>
      shallowMergeAnalyticsData({
        lastAnalyticsPagePath: newLastAnalyticsPagePath,
      });

    const setRefererPath = (newRefererPath: string | null) =>
      shallowMergeAnalyticsData({ refererPath: newRefererPath });

    const setEnterPageTs = () => {
      shallowMergeAnalyticsData({ enterPageTs: Date.now() });
    };

    /* Identify route changes with Segment Analytics once the user is identified or reset.
    analyticsPage should only be called after analyticsIdentify or analyticsReset has been
    called to ensure page events are attributed to the correct user. Accordingly the sequence 
    of the logic matters for both the initial page load and changes in authentication 
    status. */
    if (
      (analyticsUserIdentified || anonymousUserIdentified) &&
      isReady &&
      routerPath !== lastAnalyticsPagePath
    ) {
      setRefererPath(lastAnalyticsPagePath);
      setLastAnalyticsPagePath(routerPath);
      // lastAnalyticsPagePath has to be passed in when analyticsEnhancedPage is called because refererPath is updated async
      analyticsEnhancedPage(lastAnalyticsPagePath);
      if (lastAnalyticsPagePath !== null) {
        setEnterPageTs();
      }
    }
  }, [
    analyticsEnhancedPage,
    enterPageTs,
    isReady,
    lastAnalyticsPagePath,
    routerPath,
    shallowMergeAnalyticsData,
    analyticsUserIdentified,
    anonymousUserIdentified,
  ]);

  useEffect(() => {
    const trackPageExit = () => {
      analyticsEnhancedTrack("Page Exit", {
        timeOnPage: Math.floor((Date.now() - enterPageTs) / 1000),
      });
      setAnalyticsContentProperties(null);
    };
    router.events.on("routeChangeStart", trackPageExit);
    return () => {
      router.events.off("routeChangeStart", trackPageExit);
    };
  }, [
    analyticsEnhancedTrack,
    enterPageTs,
    router,
    setAnalyticsContentProperties,
  ]);

  return null;
};
