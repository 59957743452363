import { Listbox } from "@headlessui/react";
import { cx } from "cva";
import { ReactNode, forwardRef, useId } from "react";
import { Card, Icon } from "~/ui-library";
import { OptionData } from "~/ui-library/components/Select/Option";
import { ChevronDownIcon } from "~/ui-library/icons";
import { sharedInputStyles } from "~/ui-library/shared-styles/shared-input-styles";

export type SelectProps<TOption extends OptionData> = {
  children: ReactNode;
  /** class passed to the underlying listbox button which acts as the select component */
  selectClassName?: string;
  label?: string;
  onChange?: (value: TOption) => void;
  placeholder?: ReactNode;
  renderSelected?: (value: TOption) => any;
  selected?: TOption | null;
};

/**
 * Component that will render a select input.
 * This uses [@headlessui Listbox](https://headlessui.com/react/listbox) under the hood.
 *
 * Use this in conjunction with SelectOption as children to render:
 *
 * @example
 * import { Select, Option } from "~/components/Select";
 * <Select>
 *   <Option value={{id: 0, name: "Foo bar"}}>Foo bar</Option>
 * </Select>
 */
export const Select = forwardRef<HTMLElement, SelectProps<OptionData>>(
  (
    {
      children,
      selectClassName,
      label,
      onChange,
      placeholder,
      renderSelected,
      selected,
      ...rest
    },
    ref
  ) => {
    const inputId = useId();

    return (
      <div className="flex w-full flex-col gap-xxs" {...rest}>
        <Listbox onChange={onChange} ref={ref} value={selected}>
          {label ? (
            <Listbox.Label htmlFor={inputId}>{label}</Listbox.Label>
          ) : null}
          <Listbox.Button
            aria-label="Select button"
            className={cx(
              sharedInputStyles,
              "h-[40px]",
              // Once supporting a disabled property, only use this when disabled
              "hover:shadow-0",
              selectClassName
            )}
            id={inputId}
          >
            <div className="flex items-center justify-between">
              <div data-testid="Selected-item">
                {placeholder && !selected ? (
                  <div className="text-gray-400">{placeholder}</div>
                ) : null}

                {renderSelected && selected
                  ? renderSelected?.(selected!)
                  : null}
                {!renderSelected && selected ? selected.label : null}
              </div>
              <Icon
                className="float-right"
                SvgIcon={ChevronDownIcon}
                size="small"
              />
            </div>
          </Listbox.Button>
          <div className="relative z-30">
            <Listbox.Options
              className="absolute w-full outline-brand"
              data-testid="select-options"
            >
              <Card
                className=" max-h-[200px] overflow-y-auto outline-brand"
                elevation={1}
              >
                {children}
              </Card>
            </Listbox.Options>
          </div>
        </Listbox>
      </div>
    );
  }
);

Select.displayName = "SelectInput";
