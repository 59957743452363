import useSWR from "swr";
import { fetchOnboardingUserInfo } from "~/lib/client/endilApiCalls";

export const useOnboardingUserInfo = ({
  userId,
}: {
  userId: string | undefined;
}) =>
  useSWR(
    userId ? [userId, "onboarding-user-info"] : null,
    () => fetchOnboardingUserInfo().then((resp) => resp.data),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
    }
  );
