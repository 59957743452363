import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { constants } from "~/common/constants";
import { useAnalytics } from "~/components/Analytics";
import { ConditionalError } from "~/components/ConditionalError";
import { useRegistrationReminderBanner } from "~/components/PersistentState";
import { TurnstileWidget } from "~/components/TurnstileWidget";
import { createUserAccount } from "~/lib/client/endilApiCalls";
import { useTurnstileHelper } from "~/lib/client/useTurnstileHelper";
import {
  EMAIL_FIELD_SCHEMA,
  FIRST_NAME_FIELD_SCHEMA,
  LAST_NAME_FIELD_SCHEMA,
} from "~/lib/shared/schemas/fieldSchemas";
import { Body, Button, Input } from "~/ui-library";

const schema = z.object({
  firstName: FIRST_NAME_FIELD_SCHEMA,
  lastName: LAST_NAME_FIELD_SCHEMA,
  email: EMAIL_FIELD_SCHEMA,
  [constants.TURNSTILE_RESULT_PROPERTY]: z.string().nullable().optional(),
});

export type RegisterAccountFormFields = z.infer<typeof schema>;

export type RegisterAccountFormProps = {
  onSubmit: (formValues: RegisterAccountFormFields) => void;
  postLoginRedirect: string;
};

export const RegisterAccountForm: React.FC<RegisterAccountFormProps> = ({
  onSubmit,
  postLoginRedirect,
}) => {
  const { analyticsEnhancedTrack } = useAnalytics();
  const { setRegistrationReminderEmail } = useRegistrationReminderBanner();
  const {
    enableTurnstile,
    resetTurnstile,
    trackTurnstileError,
    setTurnstileResult,
  } = useTurnstileHelper();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<RegisterAccountFormFields>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    mode: "onBlur",
    resolver: zodResolver(schema),
    criteriaMode: "all",
  });

  const createAccountSubmitHandler = handleSubmit(
    async (formData: RegisterAccountFormFields) => {
      try {
        if (enableTurnstile && !formData.turnstileResult) {
          return;
        }

        clearErrors();
        const { firstName, lastName, email, turnstileResult } = formData;
        await createUserAccount({
          firstName,
          lastName,
          email,
          turnstileResult,
        });
        resetTurnstile();
        setValue(constants.TURNSTILE_RESULT_PROPERTY, undefined);
        window.localStorage.setItem(
          constants.REGISTRATION_REDIRECT_STORAGE_KEY,
          postLoginRedirect
        );
        setRegistrationReminderEmail(email);
        analyticsEnhancedTrack("Registration Submitted");
        onSubmit(formData);
      } catch (err: any) {
        setError("root", err);
        resetTurnstile();
      }
    }
  );

  return (
    <form onSubmit={createAccountSubmitHandler}>
      <div className="grid grid-cols-1 gap-s">
        <div>
          <Input
            error={errors.firstName?.message}
            fullWidth
            label="First Name*"
            {...register("firstName")}
          />
        </div>

        <div>
          <Input
            fullWidth
            label="Last Name*"
            error={errors.lastName?.message}
            {...register("lastName")}
          />
        </div>

        <div>
          <Input
            fullWidth
            error={errors.email?.message}
            label="Email Address*"
            {...register("email")}
          />
        </div>

        {enableTurnstile ? (
          <div>
            <TurnstileWidget
              theme="light"
              onVerify={(token) => {
                setTurnstileResult(token);
                setValue(constants.TURNSTILE_RESULT_PROPERTY, token, {
                  shouldValidate: true,
                });
              }}
              onError={trackTurnstileError}
            />
            <ConditionalError error={errors.turnstileResult?.message} />
          </div>
        ) : null}
      </div>

      <Body className="my-s text-center text-gray-800" size="small">
        Note: you will be asked to verify your email in the next step
      </Body>

      <Button
        variant="primary"
        type="submit"
        fullWidth
        className="mb-m"
        updating={isSubmitting}
        data-testid="submit-button"
      >
        Submit and continue
      </Button>

      <ConditionalError
        error={
          errors.root
            ? "An unexpected error occurred. Please try again or contact support at pro@morningconsult.com."
            : null
        }
      />
    </form>
  );
};
