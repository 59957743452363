import { z } from "zod";

export const featureFlagBoolSchema = z.coerce
  .string()
  .regex(/^true|false|0|1$/i)
  .transform((value) => ["true", "1"].includes(value.toLowerCase()));

export const featureFlagNumberSchema = z.coerce
  .string()
  .transform((value, ctx) => {
    if (!Number.isFinite(Number.parseInt(value, 10))) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Feature flag is not a finite number",
      });
      return z.NEVER;
    }
    return Number.parseInt(value, 10);
  });

export const defaultFeatureFlags = {
  enableFreeTrial: true,
  enableNewNavigation: true,
  enableNewSearch: false,
  enableSidecar: true,
  enable_data_access_pricing_update: false,
  enable_new_identity_providers: false,
  enable_reload_on_login: true,
  trial_length: 10,
} as const;

/**
 * This is a plain object to enable iterating over enumerable
 * keys in a couple of contexts.
 */
export const featureFlagSchema = z.object({
  enableFreeTrial: featureFlagBoolSchema.catch(
    () => defaultFeatureFlags.enableFreeTrial
  ),

  enableNewNavigation: featureFlagBoolSchema.catch(
    () => defaultFeatureFlags.enableNewNavigation
  ),

  enableNewSearch: featureFlagBoolSchema.catch(
    () => defaultFeatureFlags.enableNewSearch
  ),

  enableSidecar: featureFlagBoolSchema.catch(
    () => defaultFeatureFlags.enableSidecar
  ),

  enable_data_access_pricing_update: featureFlagBoolSchema.catch(
    () => defaultFeatureFlags.enable_data_access_pricing_update
  ),

  enable_new_identity_providers: featureFlagBoolSchema.catch(
    () => defaultFeatureFlags.enable_new_identity_providers
  ),

  enable_reload_on_login: featureFlagBoolSchema.catch(
    () => defaultFeatureFlags.enable_reload_on_login
  ),

  trial_length: featureFlagNumberSchema.catch(
    () => defaultFeatureFlags.trial_length
  ),
});

export type FeatureFlags = z.infer<typeof featureFlagSchema>;
